import { buildUrl } from '@hipagesgroup/utils';
import { resolveHost } from './utils/resolve-host';

export const gatewayUrlResolver = () =>
  buildUrl(
    resolveHost({
      staging: 'https://gateway.k8s.hipages.com.au',
      production: 'https://gateway.hipages.com.au',
    }),
    ''
  );

export const gatewayUrl = gatewayUrlResolver();

export const supoUrlResolver = () =>
  buildUrl(
    resolveHost({
      staging: 'https://master-supo.k8s.hipages.com.au',
      production: 'https://api.hipages.com.au',
    }),
    '/api/supo'
  );

export const supoUrl = supoUrlResolver();

export const hipNewUrlResolver = () =>
  buildUrl(
    resolveHost({
      staging: 'https://master-hip-new-www.k8s.hipages.com.au',
      production: 'https://hipages.com.au',
    }),
    '/'
  );

export const hipNewUrl = hipNewUrlResolver();

export const consumerApiResolver = () => {
  return buildUrl(
    resolveHost({
      staging: 'https://master-hip-new-api.k8s.hipages.com.au',
      production: 'https://api.hipages.com.au',
    }),
    '/hip-new/consumer/v1'
  );
};

export const consumerApiUrl = consumerApiResolver();

export const consumerApiUrl_DANGEROUSLY_USE_PROD = buildUrl(
  resolveHost({
    staging: 'https://api.hipages.com.au',
    production: 'https://api.hipages.com.au',
  }),
  '/hip-new/consumer/v1'
);

export const attachmentUrlResolver = () =>
  buildUrl(
    resolveHost({
      staging: 'https://master-attachments-api.k8s.hipages.com.au',
      production: 'https://api.hipages.com.au',
    }),
    '/v1/attachments'
  );

export const attachmentUrl = attachmentUrlResolver();

export const cerebroUrlResolver = () =>
  buildUrl(
    resolveHost({
      staging: 'https://cerebro.k8s.hipages.com.au',
      production: 'https://cerebro.k8s.hipages.com.au',
    }),
    '/'
  );

export const cerebroUrl = cerebroUrlResolver();

export const searchServiceUrl = buildUrl(
  resolveHost({
    staging: 'https://platform-search-service.k8s.hipages.com.au',
    production: 'https://platform-search-service.k8s.hipages.com.au',
  }),
  '/'
);

export const jobAcquisitionServiceUrl = buildUrl(
  resolveHost({
    staging: 'https://jobs-acquisition-master.k8s.hipages.com.au',
    production: 'https://gateway.hipages.com.au',
  }),
  '/v1'
);

export const directoryApiBaseUrlResolver = () =>
  buildUrl(
    resolveHost({
      // NOTE: Intentionally using prod url on staging, so that there's good complete on OPUS branch deployments
      staging: 'https://gateway.hipages.com.au/directory',
      // NOTE: Intentionally using prod url on staging, so that there's good complete on OPUS branch deployments
      production: 'https://gateway.hipages.com.au/directory',
    }),
    '/v1'
  );

export const directoryApiBaseUrl = directoryApiBaseUrlResolver();
