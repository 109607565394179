import { isProdHost } from '@hipagesgroup/utils';

/**
 * Get different hostname of API depending on the environment (clientside only)
 */
export const resolveHost = ({
  /* Staging client side hostname */
  staging,
  /* Production client side hostname */
  production,
}: {
  staging: string;
  production: string;
}): string => {
  // Client side
  return isProdHost() ? production : staging;
};
