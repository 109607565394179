import { RemixBrowser } from '@remix-run/react';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import {
  initializeGetQuotes,
  isGetQuotesConfigInitialized,
  HipagesWebPlatform,
} from '@hipagesgroup/get-quotes';
import {
  hipNewUrlResolver,
  attachmentUrlResolver,
  supoUrlResolver,
  cerebroUrlResolver,
  gatewayUrlResolver,
  consumerApiResolver,
  directoryApiBaseUrlResolver,
} from './clients';

startTransition(() => {
  // Recommended to initialize client side libraries here according to:
  // https://remix.run/docs/en/main/file-conventions/entry.client

  if (!isGetQuotesConfigInitialized()) {
    initializeGetQuotes({
      appName: HipagesWebPlatform.GetQuote,
      apiEndpointConfig: {
        attachmentUrl: attachmentUrlResolver,
        supoUrl: supoUrlResolver,
        hipNewUrl: hipNewUrlResolver,
        cerebroUrl: cerebroUrlResolver,
        gatewayUrl: gatewayUrlResolver,
        consumerApiUrl: consumerApiResolver,
        directoryUrl: directoryApiBaseUrlResolver,
      },
    });
  } else {
    console.warn('Attempted reinitialization of GQ package when not needed');
  }

  hydrateRoot(
    document,
    //<StrictMode> Turn off strict mode because get-quotes package doesn't work properly with it on
    <RemixBrowser />
    //</StrictMode>
  );
});
